import React from 'react';
import styled from 'styled-components';
import { Imagem } from './styles';
// import { Image } from 'react-native';

// export const styles = styled.div`
//   logo: {
//     height: 128,
//     width: 128,
//   }
// `; <Image style={divStyle.logo} source={props.source} />

// source={require(`../../assets/img/samuel-22.jpg`)} 
//backgroundImage={props.source} 
function Foto(props) {
  return (
    <Imagem >
        <img style={style.logo} src={props.source}  />
    </Imagem>
  );
}
export default Foto;

const style = {
  logo: {
    height: 158,
    width: 158,
  }
};
/*
const styles = StyleSheet.create({
  logo: {
    height: 128,
    width: 128,
  }
});*/