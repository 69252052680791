import styled from 'styled-components';

export const Imagem = styled.image`
  background: var(--white);
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`}; 
  background-repeat: no-repeat;
  border-top: 0px solid var(--primary);
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--black);
  text-align: center;
  @media (max-width:0px) {
    margin-bottom: 50px;
  }
  

`;

//background-image: ${({ backgroundImage }) => `url(${backgroundImage})`}; 

//background: var(--white);
// background-image:'img_girl.jpg'