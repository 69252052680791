import styled from 'styled-components';

const Button = styled.button`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
  border-color: ${props => props.theme.back};
  width:80px;
  height:60px;
 
  /* Color the border and text with theme.main */
  color: ${props => props.theme.main};
  border: 2px solid ${props => props.theme.main};
  background-color: ${props => props.theme.back};

  button:hover, button:focus{
    background-color: #0074a9;
    outline: none;
  }

  button:active {
  background: #e5e5e5;
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
   outline: none;
  }

  &:hover,
  &:focus {
    opacity: 0.9;
  }
`;

export default Button;