import React from 'react';

import { FooterBase } from './styles';

function Footer() {
  return (
    <FooterBase>
         Eleições 2020 Sossego
    </FooterBase>
  );
}

export default Footer;

/*
https://snack.expo.io/dUP!Tk6nm?session_id=snack-session-EsKbyf!6H&preview=true&platform=web&iframeId=4d8xx5oy1o&supportedPlatforms=ios,android,web&name=FlatList%20Basics&description=Example%20usage&waitForData=true
<FooterBase>
  <a href="https://www.alura.com.br/">
    <img src="https://www.alura.com.br/assets/img/alura-logo-white.1570550707.svg" alt="Logo Alura" />
  </a>
</FooterBase>
*/
