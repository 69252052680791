import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/Logo.png';
import './Menu.css';
import Button from '../Button';
// import ButtonLink from './components/ButtonLink';

function Menu() {
  return (
    <nav className="Menu">
      <Link to="/">
        Eleições 2020 Sossego
      </Link>
    </nav>
  );
}

export default Menu;

/*
 <img className="Logo" src={Logo} alt="AluraFlix logo" />

<Button as={Link} className="ButtonLink" to="/cadastro/video">
  Novo vídeo
</Button>
*/