import React from 'react';
import VideoIframeResponsive from './components/VideoIframeResponsive';
//import ContentAreaContainer from './ContentAreaContainer';
import Foto from '../Foto';
import Button from '../Button';
import Geolocalization from '../Geolocalization';
import { BannerMainContainer, ContentAreaContainer, Main, WatchButton } from './styles';
import Select from 'react-select';

function getYouTubeId(youtubeURL) {
  return youtubeURL
    .replace(
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/,
      '$7',
    );
}

function setCookie(cname,cvalue,exdays) {
  var d = new Date();
  //d.setTime(d.getTime() + (exdays*24*60*60*1000));
  //d.setTime(d.getTime() + (exdays*24*60*1*1000));
  d.setTime(d.getTime() + (exdays*60*1000));
  var expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function checkCookie() {
  var user=getCookie("username");
  if (user != "") {
    alert("Welcome again " + user);
  } else {
     //user = prompt("Please enter your name:","");
     user = setNumeroIdVoto();
     if (user != "" && user != null) {
       setCookie("username", user, 1);
     }
  }
}

function setNumeroIdVoto() {
  var d = new Date();
  var n = d.getTime();
  return n.toString()+(Math.floor((Math.random() * 50000) + 10)).toString();
}

function moveBarPorcentagemNeide(porcentagem) {
  var elem = document.getElementById("myBarNeide");   
  var width = 1;
  var id = setInterval(frame, 10);
  function frame() {
    if (width >= porcentagem) {
      clearInterval(id);
    } else {
      // width++; 
      //elem.style.width = width+'%'; 
      elem.style.width = width; 
    }
  }
}

function moveBarPorcentagemSamuel(porcentagem) {
  var elem = document.getElementById("myBarSamuel");   
  var width = 1;
  var id = setInterval(frame, 10);
  function frame() {
    if (width >= porcentagem) {
      clearInterval(id);
    } else {
      width++; 
      elem.style.width = width; 
    }
  }
}

export default function BannerMain({
  videoTitle,
  videoDescription,
  url,
}) {
  const youTubeID = getYouTubeId(url);
  //const bgUrl = `https://img.youtube.com/vi/${youTubeID}/maxresdefault.jpg`;
  //const bgUrl = `../../assets/img/sossego.jpg`;
  const bgUrl = `http://www.pm.pb.gov.br/portal/wp-content/uploads/2014/01/8962_12145.jpg`;
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]

  const porcentagemNeide = 30;
  const porcentagemSamuel = 70;

  const myBarNeide = {
    width: porcentagemNeide,
    height: 30, 
    backgroundColor: '#FFFF00',
  };

  const myBarSamuel = {
    width: porcentagemSamuel,
    height: 30, 
    backgroundColor: '#00BFFF', //  DodgerBlue
    textAlign: 'center',
  };

  const myProgress = {
    width: 200,
    backgroundColor: '#FFFFFF',
  };

  const textColor = {
    color: '#FFFF222',
    backgroundColor: '#000000',
  };

  //{moveBarPorcentagemNeide(30)}
  //{moveBarPorcentagemSamuel(30)}

  return (
      <BannerMainContainer backgroundImage={bgUrl}>          
         {checkCookie()}
        
          <ContentAreaContainer >
          
            <Main>  
              <Foto source={require(`../../assets/img/samuel-22.jpg`)} />  
              <Foto source={require(`../../assets/img/neide-23.jpg`)}/>
            </Main>
            <Main>  
              <Button theme={{ main: "#", back: "#23e7ff" }}>22</Button>
              <Button theme={{ main: "#", back: "#ffdf00" }}>23</Button> 
            </Main>

            <Main>  
              <div style={myProgress} >
                <div id="myBarSamuel" style={myBarSamuel}> <font color="green" class="text-align: justify-all">10%</font></div>
              </div> 
            </Main>
            <br/>
            <Main>  
              <div style={myProgress} >
                <div id="myBarNeide" style={myBarNeide}><font color="green">10%</font></div>
              </div>
              
            </Main>
          </ContentAreaContainer>
          
      </BannerMainContainer>
  );
}
//darkorange

const style = {
  content: {
    background: "#984484",
  },
  myProgress:{
    width: 100,
    //background: #ddd
    background: '#4CAF50',
  },
  myBar:{
    width: 61.2,
    height: 30,
    //background-color: #4CAF50;
    background: '#4CAF50',
    
    //text-align: center;
    //line-height: 30px;
    color: '#4CAF50'
    //color: var(--white);
  }
  
};
// <ProgressBar1 now={89} label={'Samuel'}  />
// <Geolocalization></Geolocalization>
          

/*
<Select       
  cacheOptions
  defaultOptions
  options={options} />

<ContentAreaContainer.Item>
 </ContentAreaContainer.Item>    
 <ContentAreaContainer.Title>
  {videoTitle}
</ContentAreaContainer.Title>
<ContentAreaContainer.Title>
  {videoTitle}
</ContentAreaContainer.Title>

<ContentAreaContainer.Description>
  {videoDescription}
</ContentAreaContainer.Description>
<ContentAreaContainer.Item>
  <VideoIframeResponsive
    youtubeID={youTubeID}
  />
</ContentAreaContainer.Item>

<WatchButton>
  Assistir
</WatchButton>
*/

